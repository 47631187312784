
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text">
                  スカイラインやメルセデスAMGなど、希少な中古車をお探しではありませんか？<br>
                  実車確認も大歓迎です。まずは在庫一覧をご覧いただき、お気軽にお問い合わせください。<br>
                  欲しい車が見つからない場合は、代行してオークションで探すことも可能です。<br>
                  もちろんお客様のお車の買取りもいたします。<br>
                  眺めているだけでもワクワクする、あの頃憧れたスカイラインGT-R。<br>
                  いつかは乗りたいAMG。<br>
                  国産車・外車を問わず、MT車もAT車も、プレミアムカーを中心に取り揃えております。
                </p>
              </div>
              <div class="button-area uk-visible@s">
                <div class="btn-copy">クリック</div>
                <a href="https://www.worldingsysdom.com/stock_info/list/car" class="catch_btn btn-c btn--yellow btn--cubic">スカイラインをはじめ<br>希少な中古車のお得情報はこちら</a>
                <!-- <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a> -->
              </div>
            </div>
          </div>

          <div class=" uk-hidden@s">
              <div class="btn-copy">クリック</div>
              <a href="https://www.worldingsysdom.com/stock_info/list/car" class="catch_btn btn-c btn--yellow btn--cubic">スカイラインをはじめ<br>希少な中古車のお得情報はこちら</a>
              <!-- <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a> -->
          </div>

          <div class="sub-card"  >
            <speech-bubble :mode="'left'" :memberName="'前川'" :path="'member-maekawa.png'"
             :massage="'私たちMIXYARDは大手ではありません。だからこそ、乗りたかったあの名車・希少な旧車を本当に手に入れたい人へとお譲りすることができます。\n' + 
                        'しかも余計な費用を掛けることなく、スカイラインやメルセデスAMGなど、国産車・外車ともに驚きの低価格で！\n\n' + 
                        '希少な中古車をお探しの方は一見の価値ありですよ！！'" 
              uk-scrollspy="cls: uk-animation-shake; repeat: true;delay: 200"/>
          </div>

          <div class="sub-card"  >
            <div class="section-title">
              <span >取り扱いメーカー・車種</span>
            </div>
            <div class="section-main " >
              <h2 class="heading-number" data-number="■">メーカー</h2>
              【国産車】日産、ホンダ 等<br>
              【外車】メルセデスAMG、ポルシェ、ランボルギーニ
              <h2 class="heading-number" data-number="■">車種</h2>
              <ul class="uk-column-1-2@s uk-column-1-3@m uk-column-1-4@l uk-column-1-5@xl">
                <li>日産 GT-R</li>
                <li>日産 スカイラインGT-R</li>
                <li>ホンダ NSX</li>
                <li>メルセデスAMG G63</li>
                <li>メルセデスAMG CLA45S</li>
                <li>ポルシェ 911カレラ</li>
                <li>ポルシェ スパイダー</li>
                <li>ポルシェ 718ケイマン</li>
                <li>ポルシェ 718ボクスター</li>
                <li>ポルシェ カイエン</li>
                <li>ポルシェ マカン</li>
                <li>ホンダ シビック</li>
                <li>ホンダ S2000</li>
                <li>メルセデスAMG GT</li>
                <li>ランボルギーニ ウルス</li>
              </ul>
            </div>
            <div class="section-title">
              <span >中古車ご購入の流れ</span>
            </div>
            <div class="section-main " >
              <h2 class="heading-number" data-number="01">欲しい車を見つける</h2>
              在庫ページよりお気に入りの１台を見つけてください。<br><br>
              <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>在庫一覧へ</span></a>

              <h2 class="heading-number" data-number="02">お問い合わせ・商談</h2>
              気になる車体がございましたら、問い合わせフォームまたは電話にてお気軽にお問い合わせください。<br>
              弊社ヤードに在庫として保管してありますので、直接ご覧いただくことも可能です。<br>
              もしお気に入りのお車が見つかった場合、内金をお預かりすることでそのお車を抑えることもできます。（キャンセルの場合、内金はお返しすることが出来ません。）<br><br>
              また、欲しい車が見つからない場合は、代行してオークションでお探しします。
              <p style="margin: 0;"><router-link to="/contact" class="c-text-anchor c-text-mark" >お問い合わせはこちら</router-link></p>
              <p style="margin: 0;"><a href="tel:0280236262" class="c-text-anchor c-text-mark">お電話はこちら：0280-23-6262</a></p>

              <h2 class="heading-number" data-number="03">ご契約</h2>
              お見積もり、契約内容にご納得いただけましたら、契約を行います。<br>
              MIXYARDではローンを扱っておりませんので、お手数ですがお客様自身で銀行等のオートローンを契約してください。

              <h2 class="heading-number" data-number="04">お支払い</h2>
              当社指定の金融機関にお振込ください。店頭での現金払いも可能です。<br>
              申し訳ございませんが、クレジットカードや分割払いは取り扱いがございませんので、ご了承ください。
              
              <h2 class="heading-number" data-number="05">弊社にて点検・整備</h2>
              お客様が安心してお乗りいただけるよう、納車前に点検・整備を行います。

              <h2 class="heading-number" data-number="06">納車</h2>
              弊社のセルフローダーにてご自宅まで陸送可能です。遠方の場合は、弊社契約の陸送会社がお届け致します。

            </div>
          </div>

          <div class="sub-card"  >
            <div class="section-title">
              <span >よくあるご質問</span>
            </div>
            <div class="section-main " >

              <h2 class="heading-number" data-number="Q">購入するときローンは組めますか？</h2>
              <div class="sub-card"  >
                <speech-bubble :mode="'right'" :memberName="'前川'" :path="'member-maekawa.png'"
                :massage="'オートローンの取扱いはございません。金利の低い銀行ローンをお勧めします。'" />
              </div>
              <h2 class="heading-number" data-number="Q">現車確認は出来ますか？</h2>
              <div class="sub-card"  >
                <speech-bubble :mode="'right'" :memberName="'前川'" :path="'member-maekawa.png'"
                :massage="'もちろん可能です。事前にご連絡をいただけますとスムーズに対応できますのでお電話又はメールにてご予約ください。'" />
              </div>
              <h2 class="heading-number" data-number="Q">遠方ですが購入できますか？</h2>
              <div class="sub-card"  >
                <speech-bubble :mode="'right'" :memberName="'前川'" :path="'member-maekawa.png'"
                :massage="'全国各地のお客様への納車に対応しております。\n' +
                          '遠方のお客様にも安心してご検討いただけますようお車の状態につきましては細かくご説明させていただいております。\n' + 
                          '現車を目の前にしてお電話でのご説明やご希望箇所の画像送信等、\nお客様が「自身の目で見た」感覚に近付けますようサポートさせていただきます。'" />
              </div>
              <h2 class="heading-number" data-number="Q">希望の車を探してもらえますか？</h2>
              <div class="sub-card"  >
                <speech-bubble :mode="'right'" :memberName="'前川'" :path="'member-maekawa.png'"
                :massage="'ご希望のお車をお探しいたしますので、お気軽にご相談ください。'" />
              </div>
              <h2 class="heading-number" data-number="Q">購入費用はいつまでに支払うのでしょうか？</h2>
              <div class="sub-card"  >
                <speech-bubble :mode="'right'" :memberName="'前川'" :path="'member-maekawa.png'"
                :massage="'お車の納車前もしくは名義変更登録が完了する前にお願いします。\n' + 
                          '遠方の場合は、納車陸送手配前にお支払いいただきます。'" />
              </div>
              <h2 class="heading-number" data-number="Q">購入時に希望ナンバーを依頼することはできますか？</h2>
              <div class="sub-card"  >
                <speech-bubble :mode="'right'" :memberName="'前川'" :path="'member-maekawa.png'"
                :massage="'ご要望があればお受けいたします。抽選番号に該当した場合は少々日数がかかります。'" />
              </div>
            </div>
          </div>
          <column-footer :title="'スカイラインやメルセデスAMGなど旧車・希少な中古車ならcar shop MIX'"/>
          <link-list ></link-list>
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import LinkList from '../../modules/parts/LinkList.vue'
import SpeechBubble from '../../modules/parts/SpeechBubble.vue'
import ColumnFooter from "../column/Footer.vue";

export default {
  components: {
    LinkList,
    SpeechBubble,
    ColumnFooter,
  },
  mounted: function() {
    let description = this.commonSetting.jigyoMenuPartsList.OldCars.subject
    document.querySelector("meta[name='description']").setAttribute('content', this.$_chlang(description))
  }
}
</script>



<style scoped lang="scss">

.parallax-bg {
  background-image:url(~@/assets/img/bg-image1.jpg);
}
.sub-card-top{
  background-image:url(~@/assets/img/page-jigyo-top-old-cars.jpg);
}

.c-text-anchor {color: #fff;letter-spacing: 1.5px;text-decoration: underline;transition: .3s;word-break: break-all;padding: 0 3px;}
.c-text-anchor:hover {color: #c49a00;text-decoration: none;}
.c-text-mark {display: inline-block;margin-top: 18px;position: relative;padding-left: 20px;}
.c-text-mark::before{content: '';width: 7px;height: 7px;border: 0px;border-top: solid 2px #c49a00;border-right: solid 2px #c49a00;transform: rotate(45deg);position: absolute;top: 0;left: 0;margin: auto;bottom: 0;}


</style>
