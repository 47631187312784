
<template>

  <div class="sub-card"  >
    <div class="section-title">
        <span>{{ title }}</span>
    </div>
    <div class="section-main">
        <div v-for="(profile, i) in profiles" :key="i" class="access-list " uk-grid>
          <div class="uk-width-1-4@m" style="" uk-leader>{{profile.title}}</div>
          <div v-if="profile.tag" class="uk-width-3-4@m link" v-html="profile.tag"></div>
          <div v-else class="uk-width-3-4@m">{{profile.detail}}</div>
        </div>
    </div>
  </div>
</template>
  
  
<script>
  export default {
    props: {
      title: {
        type: String,
        default: '人気のGT-RやメルセデスAMGなどの中古車をお探しならcar shop MIXへ'
      }
    },
    data: () => ({
      profiles:[],
    }),
    created() {
      // メインメニュー作成
      this.profiles = this.commonSetting.companyProfileContact;
    },
  }
</script>
  
<style scoped lang="scss">
.section-title{
  font-size: 1.5rem;
}
</style>
  