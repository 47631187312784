<template>
    <div>
      <div class="talking">
        <figure :class="'talking-' + mode + '_icon' ">
            <img :src="require('@/assets/img/'+path)" >
            <figcaption>{{memberName}}</figcaption>
        </figure>
        <div :class="'talking-' + mode ">
            <p>{{massage}}</p>
        </div>
      </div>
      
    </div>
</template>

<script>
export default {
  props: {
    path: { type: String },
    memberName: { type: String },
    mode: { type: String },
    massage: { type: String },
  },
}

</script>



<style scoped lang="scss">


/*------------------------------
  画像付き吹き出し(会話)
------------------------------*/
/* 吹き出し 全体*/
.talking {
  position:relative;

    &:before , &:after {
      clear:both;
      content:"";
      display:block;
    }

    .talking-left_icon, .talking-right_icon {
      margin: 0 2vw;

        figcaption{ /*アイコンの下の名前*/
          text-align:center;
        }
        img {
          margin:0;
          border:2px solid #aaa;
          border-radius:50%;    /*アイコンの角丸*/
          display: block;
          margin-left: 0;
          margin-right: auto;
        }
    }

    figure {
      width:10vh;     /*アイコンの横幅*/
      height:10vh;    /*アイコンの縦幅*/
    }
    .talking-left_icon {
        float: left;
    }
    .talking-right_icon {
        float: right;
    }
    p, p :last-child {
      margin:0;
      white-space: pre-line;
    }

    /*吹き出しのセリフ部分*/
    .talking-left , .talking-right  {
      position:relative;
      padding:2rem;
      border-style:solid;    /*枠線の線種*/
      border-radius:10px;    /*セリフを入れる部分の角丸*/
      max-width: calc(100% - 10vh);
      font-size: 1.05rem;
    }

    /*---左の吹き出し---*/
    .talking-left {
      float:left;
      border-width:2px;         /*枠線の太さ*/
      border-color:rgb(146, 146, 146);   /*枠線の色*/
      background:rgb(0, 0, 0);    /*背景色*/

        &:before, &:after {
          position: absolute;
          content: '';
          border: 10px solid transparent;
          border-right: 10px solid rgb(146, 146, 146); /*三角の線になる部分*/
        }
        &:before {
          top: 15px;  left: -22px;
        }
        &:after {
          border-right: 10px solid rgb(0, 0, 0);
          top: 15px;  left: -19px;
        }

    }
    /*---右の吹き出し---*/
    .talking-right {
      float:right;
      border-width:2px;      /*枠線の太さ*/
      border-color: var(--main-color);    /*枠線の色*/
      background:rgb(46, 46, 46);     /*背景色*/

        &:before, &:after {
          position: absolute;
          content: '';
          border: 10px solid transparent;
          border-left: 10px solid  var(--main-color);  /*三角の線になる部分*/
        }
        
        &:before {
          top: 15px;   right: -22px;
        }
        &:after {
          border-left: 10px solid rgb(46, 46, 46);    
          top: 15px;   right: -19px;
        }
    }
}
</style>